<template>
    <v-app>
        <v-app-bar density="compact">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title class="text-left font-weight-light">Цифровой двойник рака легкого</v-toolbar-title>
        </v-app-bar>
        <v-navigation-drawer :width="350" class="pa-0"
                             temporary v-model="drawer">
            <v-list-item
                    class="font-weight-medium"
            >
                <v-list-subheader class="font-weight-medium">Меню</v-list-subheader>
                <template v-slot:append>
                    <v-btn
                            variant="text"
                            icon="mdi-chevron-left"
                            @click.stop="drawer = !drawer"
                    ></v-btn>
                </template>
            </v-list-item>
            <v-list nav>
                <v-list-item
                        class="text-left text-subtitle-1 font-weight-normal"
                        active-color="success"
                        to="/">
                    <p>
                        Главная
                    </p>
                </v-list-item>
                <!-- <v-divider></v-divider>
                <v-list-subheader class="font-weight-medium">Модели рака легкого:</v-list-subheader> -->
                <v-list-item
                        class="text-left text-subtitle-1 font-weight-normal"
                        active-color="success"
                        to="/MechanisticModel">
                    <p>
                        Механистическая модель
                    </p>
                </v-list-item>
                <v-list-item
                        class="text-left text-subtitle-1 font-weight-normal"
                        active-color="success"
                        to="/ImmuneModel">
                    <p>
                        Модель иммунного ответа
                    </p>
                </v-list-item>
                <v-list-item
                        class="text-left text-subtitle-1 font-weight-normal"
                        active-color="success"
                        to="/HistologicalModel">
                    <p>
                        Модель васкулярной инвазии
                    </p>
                </v-list-item>
                <v-list-item
                        class="text-left text-subtitle-1 font-weight-normal"
                        active-color="success"
                        to="/ReactionDiffuseModel"
                >
                    <p>
                        Реакционно-диффузная модель
                    </p>
                </v-list-item>
                <v-list-item
                        class="text-left text-subtitle-1 font-weight-normal"
                        active-color="success"
                        to="3dOrganotypicModel"
                >
                    <p>
                        3D органотипическая модель
                    </p>
                </v-list-item>
                <!-- <v-divider></v-divider>
                <v-list-subheader class="font-weight-medium">Анализ ЭКГ:</v-list-subheader>
                <v-list-item
                        active-color="success"
                        class="text-left text-subtitle-1 font-weight-normal"
                        to="/EcgModel">
                    <p>
                        Выявление нарушений по ЭКГ
                    </p>
                </v-list-item> -->
            </v-list>
            <!-- <v-divider></v-divider> -->
        </v-navigation-drawer>
        
        <v-main>
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}
</style>
<script>
export default {
    data: () => ({drawer: null}),
}
</script>