<template>
    <div class="home">
        <v-app color="grey-lighten-3">
            <v-container>
                <v-row class="pa-1">
                    <v-col>
                        <v-card class="mx-auto pa-2 rounded-card" max-width="800" elevation="8">
                            <div class="pa-3">
                                <h3 class="text-h4">Цифровой двойник рака легкого</h3>
                            </div>

                            <v-divider></v-divider>

                            <v-card-text class="text-subtitle-1 text-justify">
                                Новгородский государственный университет имени Ярослава Мудрого в процессе реализации
                                программы
                                НЦМУ «Цифровой биодизайн и персонализированное здравоохранение» создает цифровые модели,
                                описывающие рак легкого. Для демонстрации моделей разработана эта инструментальная
                                среда.
                            </v-card-text>
                            <v-card-text class="text-subtitle-1 text-justify">
                                Предназначена для выработки персонализированной стратегии терапии рака легкого на основе
                                использования результатов прогностического моделирования.
                            </v-card-text>
                            <v-card-text class="text-subtitle-1 text-justify">Цифровой двойник является средством
                                поступательной интеграции методов и инструментов лечения рака легкого.</v-card-text>
                            <v-card-text class="text-subtitle-1 text-justify">
                                Работа выполнена при финансовой поддержке Министерства науки и высшего образования
                                Российской
                                Федерации по программе создания и развития центра мирового уровня «Цифровой биодизайн и
                                персонализированное здравоохранение» в рамках проекта № 075-15-2022-306.
                            </v-card-text>
                        </v-card>
                        <v-card to="/MechanisticModel" class="mx-auto pa-2 mt-4 rounded-card" max-width="800" elevation="8">
                            <div class="pa-3">
                                <h3 class="text-h5">Механистическая модель</h3>
                            </div>
                            <v-divider></v-divider>
                            <v-card-text class="text-subtitle-1 text-justify">
                                Модель предназначена для прогнозирования изменения клеточного состава опухоли.
                                Модель создана на основе данных, полученных методом проточной цитометрии. 
                                Результаты моделирования могут быть использованы как
                                маркеры
                                предикции роста опухоли, метастазирования и чувствительности к противоопухолевым
                                препаратам.
                            </v-card-text>
                            <v-img src="img1.png" class="mx-auto w-75"></v-img>
                            <div class="mx-auto w-50">

                                <v-card-text class="text-subtitle-1 text-left">
                                    CTL - цитотоксические T-лимфоциты <br>
                                    M2 - опухоль-ассоциированые макрофаги <br>
                                    CAF - опухоль-ассоциированые фибропласты
                                </v-card-text>
                            </div>
                            <v-divider></v-divider>
                            <v-card-text class="text-subtitle-1 text-center">
                                Взаимодействие клеток в рамках механистической модели
                            </v-card-text>
                            <v-img src="img2.jpg" class="mx-auto w-50"></v-img>

                            <v-card-text class="text-subtitle-1 text-center">
                                Система уравнений для описания изменения клеточного состава
                            </v-card-text>
                        </v-card>
                        <v-card to="/ImmuneModel" class="mx-auto pa-2 mt-4 rounded-card" max-width="800" elevation="8">
                            <div class="pa-3">
                                <h3 class="text-h5">Модель иммунного ответа</h3>
                            </div>
                            <v-divider></v-divider>
                            <v-card-text class="text-subtitle-1 text-justify">
                                Модель предназначена для персонализированной оценки противоопухолевого иммунного ответа. <br>
                                Модель описывает взаимодействие раковых клеток(N), клеток иммунной системы: CD4
                                Т-хелперов T(H) и
                                CD8 Т-киллеров T(C).

                            </v-card-text>
                            <v-img src="img3.png" class="mx-auto w-33"></v-img>
                            <v-card-text class="text-subtitle-1 text-center">
                                Модель иммунного ответа
                            </v-card-text>
                            <v-img src="img4.png" class="mx-auto w-50"></v-img>
                            <v-card-text class="text-subtitle-1 text-center">
                                Система уравнений для описания иммунного ответа
                            </v-card-text>
                            <v-card-text class="text-subtitle-1 text-justify">
                                Рост раковых клеток представлен логистическим законом. Модель описывает супрессию
                                раковых клеток и
                                иммунный ответ. Иммунный ответ активируется через стимуляцию Т-хелперов, которую
                                осуществляют
                                раковые клетки. Т-хелперы активируют ускоренный рост численности Т-киллеров, которые и
                                ответственны
                                за прямой противоопухолевый ответ.
                            </v-card-text>
                        </v-card>
                        <v-card class="mx-auto pa-2 mt-4 rounded-card" max-width="800" elevation="8"
                            to="/HistologicalModel">
                            <div class="pa-3">
                                <h3 class="text-h5">Модель васкулярной инвазии</h3>
                            </div>
                            <v-divider></v-divider>
                            <v-card-text class="text-subtitle-1 text-justify">
                                Модель предназначена для детекции  васкулярной инвазии 
аденокарциномы легкого.
                            </v-card-text>
                        </v-card>
                        <v-card class="mx-auto pa-2 mt-4 rounded-card" max-width="800" elevation="8"
                            to="/3dOrganotypicModel">
                            <div class="pa-3">
                                <h3 class="text-h5">3D органотипическая модель</h3>
                            </div>
                            <v-divider></v-divider>
                            <v-card-text class="text-subtitle-1 text-justify">
                                Модель предназначена для персонализированного подбора химиотерапевтических препаратов. <br>
                                Ответ злокачественных опухолей рака легкого разных пациентов на одно и то же лекарство
                                может
                                значительно отличаться вследствие сильной генетической гетерогенности опухолевых клеток.
                                Участниками
                                проекта НЦМУ создана 3D мультиклеточная органотипическая модель рака легкого. На основе
                                экспериментов удалось доказать, что модель воспроизводит основные параметры исходных
                                опухолей,
                                включая морфологию, клеточный состав и состав внеклеточного матрикса. На практике
                                показано
                                преимущество мультиклеточной модели перед чистыми линиями опухолевых клеток по
                                результатам оценки
                                чувствительности к химиопрепаратам, применяемых в настоящее время для лечения
                                немелкоклеточного рака
                                легкого.
                            </v-card-text>
                        </v-card>
                        <v-card class="mx-auto pa-2 mt-4 rounded-card" max-width="800" elevation="8"
                            to="/ReactionDiffuseModel">
                            <div class="pa-3">
                                <h3 class="text-h5">Реакционно-диффузная модель</h3>
                            </div>
                            <v-divider></v-divider>
                            <v-card-text class="text-subtitle-1 text-justify">
                                Модель предназначена для прогнозирования пространственного роста опухоли. <br>
                                Для моделирования пространственного роста опухоли рака легкого разработана
                                реакционно-диффузионная
                                модель. Результаты компьютерной томографии (КТ) являются исходными данными для модели.
                                Реакционно- диффузная модель включает функциональные модули:
                                <p>
                                    &nbsp;&nbsp;&nbsp;- сегментации легких на КТ;
                                </p>
                                <p>
                                    &nbsp;&nbsp;&nbsp;- обнаружения узелков в легких;
                                </p>
                                <p>
                                    &nbsp;&nbsp;&nbsp;- выделения долей легких;
                                </p>
                                <p>
                                    &nbsp;&nbsp;&nbsp;- моделирования пространственного роста узелка.
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>

        </v-app>
    </div>
</template>
<script>
export default {
    name: 'HomeView',
    components: {},
}
</script>

<style>
.rounded-card {
    border-radius: 20px;
}
</style>
