import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/EcgModel',
    name: 'EcgModel',
    component: () => import('../views/EcgView.vue')
  },
  {
    path: '/ImmuneModel',
    name: 'ImmuneModel',
    component: () => import('../views/ImmuneView.vue')
  },
  {
    path: '/MechanisticModel',
    name: 'MechanisticModel',
    component: () => import('../views/MechanisticView.vue')
  },
  {
    path: '/HistologicalModel',
    name: 'HistologicalModel',
    component: () => import('../views/HistologicalView.vue')
  },
  {
    path: '/ReactionDiffuseModel',
    name: 'ReactionDiffuseModel',
    component: () => import('../views/ReactionDiffuseView.vue')
  },
  {
    path: '/3dOrganotypicModel',
    name: '3dOrganotypicModel',
    component: () => import('../views/OrganotypicView.vue')
  },
  { path: "/:pathMatch(.*)*",
    component: () => import('../views/PageNotFound.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0,0);
  }
})



export default router
